import React from 'react';
import { graphql } from 'gatsby';

import { FormattedMessage } from 'react-intl';
import { space } from 'styled-system';
import { Flex, Box } from '@rebass/grid';

import withLayout from '../layout';
import { SubHeader, CatOnWaves } from '../components';
import LeftFlower from '../components/Flowers/Right1';
import RightFlowers from '../components/Flowers/BottomWaves';

const AboutUs = ({ data }) => {
  return (
    <>
      <SubHeader
        images={data.slider.edges}
        modalHeader="aboutUs.who we are"
        modalText="aboutUs.aboutUs"
        buttonText="nav.seeMenu"
        linkTo="/menu"
        aboutUs
        armBottom={[0, 14, -10, 20]}
      />
      <h2
        css={`
          ${space}
          line-height: 1;
        `}
        my={0}
        mx={20}
      >
        <FormattedMessage id="aboutUs.try our asian food" />
      </h2>
      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Box
          width={1920}
          css={`
            position: relative;
          `}
        >
          <LeftFlower
            position="absolute"
            left={450}
            bottom={130}
            zIndex={3}
            display={{ mobile: 'none', laptop: 'flex' }}
          />
          <RightFlowers />
          <CatOnWaves />
        </Box>
      </Flex>
    </>
  );
};

const customProps = {
  localeKey: 'aboutUs',
  keywords: ['o nas', 'about us'],
};

export const query = graphql`
  query {
    slider: allFile(filter: { relativeDirectory: { eq: "aboutus/slider" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920, srcSetBreakpoints: [1280, 1440], quality: 100) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  }
`;

export default withLayout(customProps)(AboutUs);
